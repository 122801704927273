import React from "react";
import "./styles.css";

const Loading = () => {
  return (
    <div className="loading-container column">
      <div className="loader">
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p className="logo-title">Loading</p>
    </div>
  );
};
export default Loading;