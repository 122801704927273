import { sceneSlice } from "./sceneSlice";
import { selectUsers } from "./selectors";
import { sessionSlice } from "./sessionSlice";
import {
  createUser,
  updateUserVisibility,
  renderUserUpdate,
  removeUserFromScene,
  addElement,
  updateElementScale,
  deleteElement,
  recenterElement,
  cleanUp,
  updateElementTransform,
  updateSlide,
  startVRSession,
  startARSession,
  endVRSession,
  enableWasd,
  disableWasd,
  updatePositionFromJoystick,
  joystickUpdateEnd,
} from "../3d/index";
import { checkTouchScreen, saveSessionCookie, checkSessionCookie, checkAdminCookie, clearSessionCookie } from "./utils";
import { socket } from "./socket";

const socketMiddleware = (store) => (next) => (action) => {
  if (sessionSlice.actions.startConnecting.match(action)) {
    socket.connect();

    socket.on("connect", () => {
      store.dispatch(sessionSlice.actions.connectionStablished({ id: socket.id }));

      if ("xr" in navigator) {
        navigator.xr.isSessionSupported("immersive-vr").then(function (supported) {
          if (supported) {
            store.dispatch(sessionSlice.actions.isVrUser());
          }
        });

        navigator.xr.isSessionSupported("immersive-ar").then(function (supported) {
          if (supported) {
            store.dispatch(sessionSlice.actions.isArUser());
          }
        });
      }

      const isTouch = checkTouchScreen();
      if (isTouch) {
        store.dispatch(sessionSlice.actions.isTouchScreen());
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("roomId") || checkSessionCookie();

      if (sessionId) {
        const userType = checkAdminCookie();
        socket.emit("request:joinRoom", { roomName: sessionId, userType }, (response) => {
          console.log(response);
        });
      }
    });

    socket.on("disconnect", () => {
      store.dispatch(sessionSlice.actions.connectionLost());
      cleanUp();
    });

    socket.on("scenes:get", (data) => {
      store.dispatch(sessionSlice.actions.receivedAvailableRooms(data));
    });

    socket.on("users:get", async (data) => {
      const state = store.getState();
      store.dispatch(sceneSlice.actions.receivedStartingPackage(data));
      store.dispatch(sessionSlice.actions.receivedStartingPackage(data));

      if (data.elements.length === 0) {
        store.dispatch(sceneSlice.actions.sceneLoaded());
      } else {
        data.elements.forEach((element) => {
          addElement({ element });
        });
      }
      
      data.users.forEach((user) => {
        createUser(user);
      });

      updateSlide({ url: data.slideUrl });

      if (data.admin) {
        store.dispatch(sessionSlice.actions.setAdmin());
      }

      enableWasd();
      let userType;
      if (data.admin) {
        userType = "admin";
      } else if (data.isAttendee) {
        userType = "attendee";
      } else {
        userType = "guest";
      }
      saveSessionCookie(data.roomId, userType);

      if (state.session.isVr) {
        socket.emit("request:spawnPoint", { id: socket.id }, (response) => {
          store.dispatch(sceneSlice.actions.receivedSpawnPoint(response.spawnPoint));
        });
      }
    });

    socket.on("controller:renderUpdate", (data) => {
      if (data.id !== socket.id) {
        renderUserUpdate(data);
      }
    });

    socket.on("user:add", async (data) => {
      const state = store.getState();
      const users = selectUsers(state);

      const userIndex = users.findIndex((user) => user.id === data.id);
      const userExist = userIndex >= 0;

      if (!userExist) {
        store.dispatch(sceneSlice.actions.userAdded(data));
      }

      if (data.id !== socket.id) {
        console.log(`Connect ${data.id} ${data.type}`);
        if (userExist) {
          store.dispatch(
            sceneSlice.actions.userUpdatedViibility({
              id: data.id,
              visibility: true,
            })
          );
          updateUserVisibility(data.id, true);
        } else {
          createUser(data);
        }
      }
    });

    socket.on("user:hide", (data) => {
      if (data.id !== socket.id) {
        const state = store.getState();
        const users = selectUsers(state);

        const userIndex = users.findIndex((user) => user.id === data.id);

        if (userIndex >= 0) {
          store.dispatch(
            sceneSlice.actions.userUpdatedViibility({
              id: data.id,
              visibility: false,
            })
          );
          updateUserVisibility(data.id, false);
          console.log("Hiding user");
        }
      }
    });

    socket.on("user:remove", (data) => {
      if (data.id !== socket.id) {
        store.dispatch(sceneSlice.actions.userRemoved({ id: data.id }));
        removeUserFromScene(data.id);
      }
    });

    socket.on("user:updateGrab", (data) => {
      store.dispatch(sceneSlice.actions.permissionUpdated({ canGrab: data.canGrab }));
    });

    socket.on("scene:updateCanGrabMultipleObjs", (data) => {
      store.dispatch(
        sceneSlice.actions.canGrabMultipleObjsUpdated({
          canGrabMultipleObjs: data.canGrabMultipleObjs,
        })
      );
    });

    socket.on("element:add", async (data) => {
      store.dispatch(sceneSlice.actions.elementAdded(data));
      addElement(data);
    });

    socket.on("element:updateScale", async (data) => {
      store.dispatch(sceneSlice.actions.elementScaleUpdated(data));
      updateElementScale(data);
    });

    socket.on("element:updateEnable", async (data) => {
      store.dispatch(sceneSlice.actions.elementEnableUpdated(data));
      // updateElementScale(data);
    });

    socket.on("element:remove", async (data) => {
      store.dispatch(sceneSlice.actions.elementDeleted(data));
      deleteElement(data);
    });

    socket.on("element:recenter", async (data) => {
      recenterElement(data);
      store.dispatch(sceneSlice.actions.elementClearGrabbing(data));
    });

    socket.on("element:updateGrab", async (data) => {
      store.dispatch(sceneSlice.actions.elementGrabbingUpdated(data));
    });

    socket.on("element:renderUpdate", (data) => {
      if (data.ownerId !== socket.id) {
        updateElementTransform(data);
      }
    });

    socket.on("scene:resetEls", (data) => {
      const state = store.getState();
      state.scene.elements.forEach((el) => {
        deleteElement({ id: el.id });
      });

      data.elements.forEach((element) => {
        addElement({ element });
      });

      store.dispatch(sceneSlice.actions.updateElementList(data));
    });

    socket.on("slide:update", async (data) => {
      updateSlide(data);
    });

    socket.on("session:toggleAudio", async (data) => {
      store.dispatch(sessionSlice.actions.toggleAudioChannel(data));
    });

    socket.on("session:toggleMute", async (data) => {
      store.dispatch(sessionSlice.actions.toggleMute(data));
    });

    socket.on("session:toggleMuteGuests", async (data) => {
      store.dispatch(sessionSlice.actions.toggleMuteGuests(data));
    });

    socket.on("room:close", () => {
      store.dispatch(sessionSlice.actions.sessionClosed());
      cleanUp();
      disableWasd();
      endVRSession();
    });
  }

  if (sceneSlice.actions.emitControllerConnect.match(action)) {
    socket.emit("controller:connect", action.payload);
  }

  if (sceneSlice.actions.emitControllerDisconnect.match(action)) {
    socket.emit("controller:disconnect");
  }

  if (sceneSlice.actions.emitControllerUpdate.match(action)) {
    socket.volatile.emit("controller:update", action.payload);
  }

  if (sceneSlice.actions.emitElementSubmit.match(action)) {
    socket.emit("element:submit", action.payload);
  }

  if (sceneSlice.actions.emitScaleUpdate.match(action)) {
    socket.emit("element:scale", action.payload);
  }

  if (sceneSlice.actions.emitElementDelete.match(action)) {
    socket.emit("element:delete", action.payload);
  }

  if (sceneSlice.actions.emitElementRecenter.match(action)) {
    socket.emit("element:center", action.payload);
  }

  if (sceneSlice.actions.emitElementEnable.match(action)) {
    socket.emit("element:toggleEnable", action.payload);
  }

  if (sceneSlice.actions.emitElementGrabbed.match(action)) {
    socket.emit("element:grab", { ...action.payload, owner: socket.id });
  }

  if (sceneSlice.actions.emitElementUngrabbed.match(action)) {
    socket.emit("element:ungrab", action.payload);
  }

  if (sceneSlice.actions.emitElementUpdate.match(action)) {
    socket.emit("element:updatePos", action.payload);
  }

  if (sceneSlice.actions.emitUserPermissionUpdate.match(action)) {
    socket.emit("user:grabPermission", action.payload);
  }

  if (sceneSlice.actions.emitGrabbingMultipleObjs.match(action)) {
    socket.emit("session:canGrabMultipleObjs", action.payload);
  }

  if (sceneSlice.actions.emitSlideUpdate.match(action)) {
    socket.emit("slide:submit", action.payload);
  }

  if (sessionSlice.actions.emitToggleAudioChannel.match(action)) {
    socket.emit("session:toggleAudio");
  }

  if (sessionSlice.actions.emitToggleMute.match(action)) {
    socket.emit("session:toggleMute");
  }

  if (sessionSlice.actions.emitToggleMuteGuests.match(action)) {
    socket.emit("session:toggleMuteGuests");
  }

  if (sessionSlice.actions.emitRequestNewRoom.match(action)) {
    socket.emit("request:newRoom", action.payload);
  }

  if (sessionSlice.actions.emitRequestJoinRoom.match(action)) {
    socket.emit("request:joinRoom", action.payload, (response) => {
      store.dispatch(sessionSlice.actions.noRoomFound());
    });
  }

  if (sceneSlice.actions.enterVR.match(action)) {
    startVRSession();
  }

  if (sceneSlice.actions.enterAR.match(action)) {
    startARSession();
  }

  if (sessionSlice.actions.emitResetScene.match(action)) {
    socket.emit("scene:reset");
  }

  if (sceneSlice.actions.joystickUpdate.match(action)) {
    updatePositionFromJoystick(action.payload);
  }

  if (sceneSlice.actions.joystickEnd.match(action)) {
    joystickUpdateEnd();
  }

  if (sessionSlice.actions.emitCloseSession.match(action)) {
    socket.emit("scene:close");
  }

  if (sessionSlice.actions.emitLeaveSession.match(action)) {
    clearSessionCookie();
    cleanUp();
    socket.emit("scene:leave");
  }

  if (sessionSlice.actions.requestTrial.match(action)) {
    const state = store.getState();
    enableWasd();
    state.session.trialScene.elements.forEach((element) => {
      addElement({ element });
    });

    updateSlide({ url: state.session.trialSlide });
  }

  if (sessionSlice.actions.leaveTrial.match(action)) {
    disableWasd();
    cleanUp();
  }

  next(action);
};
export default socketMiddleware;
