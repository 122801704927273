import { connect } from "react-redux";
import App from "../components/Application";
import { sessionSlice } from "../../store/sessionSlice";

const mapStateToProps = (state) => {
  return {
    isConnectedToASession: state.session.isConnectedToASession,
    isVrUser: state.session.isVr,
    isAdmin: state.session.userType === "admin",
    isTouch: state.session.isTouch,
    isTryingRoom: state.session.isTryingRoom,
    isShowingHelp: state.session.isShowingOverlay,
    isSceneLoaded: state.scene.isSceneLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStartConnecting: (data) => {
      dispatch(sessionSlice.actions.startConnecting());
    }
  };
};

export const ApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
