import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory.js";
import { OculusHandModel } from "three/examples/jsm/webxr/OculusHandModel.js";

import { joints, createHand, createController, createAvatar, clearGroup, createElement } from "./utils.js";

import { store } from "../store";
import {
  selectIsConnected,
  selectSpawnPoint,
  selectSpawnRotation,
  selectColor,
  selectAvailableElements,
  selectScaling,
  selectGrabbingPermission,
  selectSceneStats,
  selectMultipleGrabbingPermission,
  selectisInVR,
  selectisInTrialMode,
  selectisTyping,
  selectIsShowingInfoModal,
  selectIsShowingHelpOverlay,
} from "../store/selectors.js";
import { sceneSlice } from "../store/sceneSlice.js";
import { sessionSlice } from "../store/sessionSlice.js";

let isRightControllerConnected = false;
let isLeftControllerConnected = false;
let isRHand = false;
let isLHand = false;
let isColorUpdated = false;

let userMeshes = [];
let elementMeshes = [];
const grabbingControllers = [];
const grabbedMeshes = [];
let counter = 0;
let counter2 = 0;
let debugCounter = 0;
let lastTime = 0;
const _PI_2 = Math.PI / 2;
const minPolarAngle = 0;
const maxPolarAngle = Math.PI;

const sphereMaterial = new THREE.MeshLambertMaterial({
  color: 0xffffff,
  transparent: true,
  opacity: 0.3,
});
const sphereGeometry = new THREE.SphereGeometry(1, 16, 16);
const textureLoader = new THREE.TextureLoader();

let scene;
let camera;
let renderer;
let controls;
let wasdControls;
let controller1;
let controller2;
let hand1;
let hand2;
let controllerGrip1;
let controllerGrip2;
let slideMat;
let environment;

let isMouseDown = false;
let isWasdEnabled = false;
let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let previousMouseEvent = {};
let shouldCheckInputSources = false;
const CHECK_INTERVAL = 2000;
let touchStarted = false;
let touchStart = {};

let speed = 0;

const euler = new THREE.Euler(0, 0, 0, "YXZ");

const limitBox = new THREE.Box3();
limitBox.set(new THREE.Vector3(-5, -5, -5), new THREE.Vector3(5, 5, 5));

const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();

const canvas = document.querySelector("canvas.webgl");

const dummyObj = new THREE.Object3D();

// Cursor
const pivot = new THREE.Mesh(new THREE.IcosahedronGeometry(0.01, 3));
pivot.name = "pivot";
pivot.position.z = -0.05;
pivot.position.y = -0.05;
const cursorGroup = new THREE.Group();
cursorGroup.add(pivot);

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const angleOffset = -Math.PI / 3; // 60 deg
const positionOffset = 1.8;

const getSpawnPoint2 = (angleCounter) => {
  const angle = angleOffset * angleCounter;
  const x = positionOffset * Math.cos(angle);
  const z = positionOffset * Math.sin(angle);
  angleCounter++;

  const newPoint = {
    x,
    y: 0.01,
    z,
  };
  return newPoint;
};

export const setup3DScene = () => {
  scene = new THREE.Scene();
  scene.background = new THREE.Color("skyblue");

  camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 100);
  camera.position.set(0, 1.6, 3);
  controls = new OrbitControls(camera, canvas);
  controls.target.set(0, 1.6, 0);
  controls.autoRotate = true;
  controls.autoRotateSpeed = 0.2;
  controls.enableRotate = false;
  controls.enablePan = false;
  controls.enableZoom = false;
  controls.update();

  wasdControls = new PointerLockControls(camera, canvas);

  document.addEventListener("keydown", onKeyDown);
  document.addEventListener("keyup", onKeyUp);

  const testGeometry = new THREE.CircleGeometry(0.25, 16);
  const testMaterial2 = new THREE.MeshBasicMaterial({
    color: "black",
    wireframe: true,
  });
  const center = new THREE.Mesh(testGeometry, testMaterial2);
  center.rotateX(-Math.PI / 2);
  center.position.set(0, 0.01, 0);
  scene.add(center);

  // Lights
  const hemisphereLight = new THREE.HemisphereLight(0x606060, 0x404040, 10);
  scene.add(hemisphereLight);

  const light = new THREE.DirectionalLight(0xffffff, 5);
  light.position.set(0, 6, 0);
  scene.add(light);

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.outputColorSpace = THREE.SRGBColorSpace;
  renderer.xr.enabled = true;
  // renderer.xr.cameraAutoUpdate = false;

  renderer.xr.addEventListener("sessionstart", handleSessionStart);
  renderer.xr.addEventListener("sessionend", handleSessionEnd);

  // Controllers
  controller1 = renderer.xr.getController(0);
  controller1.addEventListener("connected", onControllerConnected);
  controller1.addEventListener("disconnected", onControllerDisconnected);
  scene.add(controller1);

  controller2 = renderer.xr.getController(1);
  controller2.addEventListener("connected", onControllerConnected);
  controller2.addEventListener("disconnected", onControllerDisconnected);
  scene.add(controller2);

  const controllerModelFactory = new XRControllerModelFactory();

  // Hand1
  controllerGrip1 = renderer.xr.getControllerGrip(0);
  controllerGrip1.add(controllerModelFactory.createControllerModel(controllerGrip1));
  controllerGrip1.addEventListener("selectstart", onSelectStart);
  controllerGrip1.addEventListener("selectend", onSelectEnd);
  scene.add(controllerGrip1);
  controllerGrip1.add(cursorGroup.clone());

  hand1 = renderer.xr.getHand(0);
  hand1.add(new OculusHandModel(hand1));
  hand1.addEventListener("pinchstart", onPinchStart);
  hand1.addEventListener("pinchend", onPinchEnd);
  scene.add(hand1);

  // Hand2
  controllerGrip2 = renderer.xr.getControllerGrip(1);
  controllerGrip2.add(controllerModelFactory.createControllerModel(controllerGrip2));
  controllerGrip2.addEventListener("selectstart", onSelectStart);
  controllerGrip2.addEventListener("selectend", onSelectEnd);
  scene.add(controllerGrip2);
  controllerGrip2.add(cursorGroup.clone());

  hand2 = renderer.xr.getHand(1);
  hand2.add(new OculusHandModel(hand2));
  hand2.addEventListener("pinchstart", onPinchStart);
  hand2.addEventListener("pinchend", onPinchEnd);
  scene.add(hand2);

  environment = new THREE.Object3D();
  scene.add(environment);

  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
  loader.setDRACOLoader(dracoLoader);
  loader.load(
    // "https://cdn.glitch.global/56c73222-59c9-4ea8-ac67-46d18ffcc37e/model2.glb",
    // "https://killcloud.nyc3.cdn.digitaloceanspaces.com/scenes/ConferenceCenter.glb",
    "https://killcloud.nyc3.cdn.digitaloceanspaces.com/scenes/conf_optimized3.glb",
    function (glb) {
      glb.scene.scale.set(1, 1, 1);
      glb.scene.position.set(-11, 0, 1);
      const gallery = glb.scene;
      environment.add(gallery);
      limitBox.setFromObject(gallery);
    }
  );

  const epflLogo = textureLoader.load("images/epfl.jpg");
  const epflGeom = new THREE.PlaneGeometry(2.5, 1.1);
  const epflMat = new THREE.MeshBasicMaterial({ map: epflLogo });
  const epfl = new THREE.Mesh(epflGeom, epflMat);
  epfl.rotateY(-Math.PI / 2);
  epfl.position.set(4.85, 2.5, -1.7);
  environment.add(epfl);

  const lbmLogo = textureLoader.load("images/lbm.png");
  const lbmGeom = new THREE.PlaneGeometry(1.79, 1.1);
  const lbmMat = new THREE.MeshBasicMaterial({ map: lbmLogo });
  const lbm = new THREE.Mesh(lbmGeom, lbmMat);
  lbm.rotateY(-Math.PI / 2);
  lbm.position.set(4.85, 2.5, 1.7);
  environment.add(lbm);

  const snfLogo = textureLoader.load("images/snf.jpg");
  const snfGeom = new THREE.PlaneGeometry(3, 0.68);
  const snfMat = new THREE.MeshBasicMaterial({ map: snfLogo });
  const snf = new THREE.Mesh(snfGeom, snfMat);
  snf.rotateY(-Math.PI / 2);
  snf.position.set(4.85, 1.3, -1.7);
  environment.add(snf);

  const haslerLogo = textureLoader.load("images/hasler.jpg");
  const haslerGeom = new THREE.PlaneGeometry(3, 0.68);
  const haslerMat = new THREE.MeshBasicMaterial({ map: haslerLogo });
  const hasler = new THREE.Mesh(haslerGeom, haslerMat);
  hasler.rotateY(-Math.PI / 2);
  hasler.position.set(4.85, 1.3, 1.7);
  environment.add(hasler);

  const slideImg = textureLoader.load("https://killcloud.nyc3.cdn.digitaloceanspaces.com/scenes/slides/empty.jpeg");
  slideImg.colorSpace = THREE.SRGBColorSpace;
  const slideGeom = new THREE.PlaneGeometry(3.84, 2.16);
  slideMat = new THREE.MeshBasicMaterial({ map: slideImg });
  const slide = new THREE.Mesh(slideGeom, slideMat);
  slide.rotateY(Math.PI);
  slide.position.set(0, 2, 4.85);
  environment.add(slide);

  environment.rotateY(Math.PI);

  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });

  animate();
};

function animate() {
  renderer.setAnimationLoop(render);
}

/* ****** Render Loop  ****** */
function render() {
  const state = store.getState();
  const isConnected = selectIsConnected(state);
  const isInTrial = selectisInTrialMode(state);
  const isTyping = selectisTyping(state);
  const scaling = selectScaling(state);

  if (scaling.active) {
    let posReference1;
    let posReference2;

    if (isRHand) {
      posReference1 = hand1.joints["index-finger-tip"].position;
    } else {
      posReference1 = controllerGrip1.position;
    }

    if (isLHand) {
      posReference2 = hand2.joints["index-finger-tip"].position;
    } else {
      posReference2 = controllerGrip2.position;
    }

    const distance = posReference1.distanceTo(posReference2);
    scaling.objects.forEach((object) => {
      const newScale = (object.initialScale * distance) / object.initialDistance;
      const scalingEl = getElementById(object.objectId);
      scalingEl.mesh.scale.setScalar(newScale);
    });
  }

  if (shouldCheckInputSources) {
    const session = renderer.xr.getSession();
    const inputSourcesInfo = formatInputSources(session);
    handleControllerSetup(inputSourcesInfo);
    shouldCheckInputSources = false;
  }

  if (counter > 4) {
    if (isConnected && isLeftControllerConnected && isRightControllerConnected && !isInTrial) {
      postControllers();
    }

    counter = 0;
  }

  counter++;

  if (counter2 > 4 && !isInTrial) {
    grabbedMeshes.forEach((grabbedMesh) => {
      postElement(grabbedMesh.id);
    });
    counter2 = 0;
  }

  counter2++;

  const currentTime = performance.now();

  if (controls.enabled) {
    controls.update();
  }

  if (isWasdEnabled && !isTyping) {
    const delta = (currentTime - lastTime) / 1000;

    velocity.x -= velocity.x * 10.0 * delta;
    velocity.z -= velocity.z * 10.0 * delta;

    velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

    direction.z = Number(moveForward) - Number(moveBackward);
    direction.x = Number(moveRight) - Number(moveLeft);
    direction.normalize(); // this ensures consistent movements in all directions

    if (moveForward || moveBackward) velocity.z -= direction.z * speed * delta;
    if (moveLeft || moveRight) velocity.x -= direction.x * speed * delta;

    wasdControls.moveRight(-velocity.x * delta);
    wasdControls.moveForward(-velocity.z * delta);

    wasdControls.getObject().position.y += velocity.y * delta; // new behavior

    if (wasdControls.getObject().position.y < 10) {
      velocity.y = 0;
      wasdControls.getObject().position.y = 1.6;
    }

    if (camera.position.x > limitBox.max.x - 0.3) {
      camera.position.x = limitBox.max.x - 0.3;
    }

    if (camera.position.x < limitBox.min.x + 0.3) {
      camera.position.x = limitBox.min.x + 0.3;
    }

    if (camera.position.z > limitBox.max.z - 0.7) {
      camera.position.z = limitBox.max.z - 0.7;
    }

    if (camera.position.z < limitBox.min.z + 0.7) {
      camera.position.z = limitBox.min.z + 0.7;
    }
  }

  lastTime = currentTime;
  debugCounter++;

  renderer.render(scene, camera);
}

/* ****** Events  ****** */
function onPinchEnd(e) {
  const controller = e.target;
  if (controller.userData.selected !== undefined) {
    /* multiple collide */
    const { objects, sphere } = controller.userData.selected;
    const state = store.getState();
    const isInTrial = selectisInTrialMode(state);
    const scaling = selectScaling(state);

    const id = controller.id;
    const index = grabbingControllers.indexOf(id);
    grabbingControllers.splice(index, 1);

    scene.attach(sphere);
    sphere.geometry.dispose();
    sphere.material.dispose();
    scene.remove(sphere);

    controller.userData.selected = undefined;

    if (!scaling.active) {
      objects.forEach((object) => {
        const objectId = object.id;
        const index2 = grabbedMeshes.findIndex((grabbedMesh) => grabbedMesh.id === objectId);
        grabbedMeshes.splice(index2, 1);
        scene.attach(object.mesh);
        if (!isInTrial) {
          store.dispatch(
            sceneSlice.actions.emitElementUngrabbed({
              element: object.id,
            })
          );
        }
      });
    } else {
      store.dispatch(sceneSlice.actions.stopScaling());
    }
  }
}

function onPinchStart(e) {
  const state = store.getState();
  const isInTrial = selectisInTrialMode(state);
  const canIGrab = selectGrabbingPermission(state) || isInTrial;

  if (!canIGrab) {
    return;
  }

  const controller = e.target;
  const id = controller.id;
  const wrist = controller.joints["wrist"];
  const indexTip = controller.joints["index-finger-tip"];
  const otherIndexTip = controller === hand1 ? hand2 : hand1;
  const objects = collideMultipleObjects(indexTip);

  /* multiple collide*/

  let amIGrabbingThis = false;

  for (let i = 0; i < objects.length; i++) {
    amIGrabbingThis = grabbedMeshes.some((grabbedMesh) => grabbedMesh.id === objects[i].id);
    if (amIGrabbingThis) {
      break;
    }
  }

  if (objects.length > 0 && amIGrabbingThis) {
    grabbingControllers.push(id);
    let scalingInfo = [];

    objects.forEach((object) => {
      const info = {
        objectId: object.id,
        initialScale: object.mesh.scale.x,
        initialDistance: indexTip.position.distanceTo(otherIndexTip.joints["index-finger-tip"].position),
      };
      scalingInfo.push(info);
    });

    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.scale.set(0.05, 0.05, 0.05);
    indexTip.attach(sphere);
    sphere.position.set(0, 0, 0);

    controller.userData.selected = { objects, sphere };
    store.dispatch(sceneSlice.actions.startScaling(scalingInfo));
  }

  if (objects.length > 0 && !amIGrabbingThis) {
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.scale.set(0.05, 0.05, 0.05);
    grabbingControllers.push(id);
    objects.forEach((object) => {
      grabbedMeshes.push({ id: object.id });
      wrist.attach(object.mesh);

      if (!isInTrial) {
        store.dispatch(
          sceneSlice.actions.emitElementGrabbed({
            element: object.id,
          })
        );
      }
    });
    controller.userData.selected = { objects, sphere };
    indexTip.attach(sphere);
    sphere.position.set(0, 0, 0);
  }
}

function onSelectEnd(e) {
  const controller = e.target;
  if (controller.userData.selected !== undefined) {
    /* multiple collide */
    const { objects, sphere } = controller.userData.selected;
    const state = store.getState();
    const isInTrial = selectisInTrialMode(state);
    const scaling = selectScaling(state);

    const id = controller.id;
    const index = grabbingControllers.indexOf(id);
    grabbingControllers.splice(index, 1);

    scene.attach(sphere);
    sphere.geometry.dispose();
    sphere.material.dispose();
    scene.remove(sphere);

    controller.userData.selected = undefined;

    if (!scaling.active) {
      objects.forEach((object) => {
        const objectId = object.id;
        const index2 = grabbedMeshes.findIndex((grabbedMesh) => grabbedMesh.id === objectId);
        grabbedMeshes.splice(index2, 1);
        scene.attach(object.mesh);
        if (!isInTrial) {
          store.dispatch(
            sceneSlice.actions.emitElementUngrabbed({
              element: object.id,
            })
          );
        }
      });
    } else {
      store.dispatch(sceneSlice.actions.stopScaling());
    }
  }
}

function onSelectStart(e) {
  if (isRHand) {
    return;
  }

  const state = store.getState();
  const isInTrial = selectisInTrialMode(state);
  const canIGrab = selectGrabbingPermission(state) || isInTrial;

  if (!canIGrab) {
    return;
  }

  const controller = e.target;
  const id = controller.id;
  const otherController = controller === controllerGrip1 ? controllerGrip2 : controllerGrip1;
  const pivot = this.getObjectByName("pivot");
  const objects = collideMultipleObjects(pivot);

  /* multiple collide*/

  let amIGrabbingThis = false;

  for (let i = 0; i < objects.length; i++) {
    amIGrabbingThis = grabbedMeshes.some((grabbedMesh) => grabbedMesh.id === objects[i].id);
    if (amIGrabbingThis) {
      break;
    }
  }

  if (objects.length > 0 && amIGrabbingThis) {
    grabbingControllers.push(id);
    let scalingInfo = [];

    objects.forEach((object) => {
      const info = {
        objectId: object.id,
        initialScale: object.mesh.scale.x,
        initialDistance: controller.position.distanceTo(otherController.position),
      };
      scalingInfo.push(info);
    });

    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.scale.set(0.05, 0.05, 0.05);
    pivot.attach(sphere);
    sphere.position.set(0, 0, 0);

    controller.userData.selected = { objects, sphere };
    store.dispatch(sceneSlice.actions.startScaling(scalingInfo));
  }

  if (objects.length > 0 && !amIGrabbingThis) {
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.scale.set(0.05, 0.05, 0.05);
    grabbingControllers.push(id);
    objects.forEach((object) => {
      grabbedMeshes.push({ id: object.id });
      pivot.attach(object.mesh);

      if (!isInTrial) {
        store.dispatch(
          sceneSlice.actions.emitElementGrabbed({
            element: object.id,
          })
        );
      }
    });
    controller.userData.selected = { objects, sphere };
    pivot.attach(sphere);
    sphere.position.set(0, 0, 0);
  }
}

function onControllerConnected(event) {}

function onControllerDisconnected(event) {
  isColorUpdated = false;
}

function handleSessionStart(event) {
  controls.enabled = false;
  isWasdEnabled = false;
  setTimeout(() => {
    shouldCheckInputSources = true;
  }, CHECK_INTERVAL);
}

function handleSessionEnd(event) {
  isRightControllerConnected = false;
  isLeftControllerConnected = false;
  // controls.enabled = true;
  isWasdEnabled = true;
  environment.visible = true;
  isColorUpdated = false;
  store.dispatch(sceneSlice.actions.emitControllerDisconnect());
}

/* ****** Functions  ****** */
export function cleanUp() {
  elementMeshes.forEach((element) => {
    clearGroup(element.mesh);
    scene.remove(element.mesh);
  });

  userMeshes.forEach((user) => {
    clearGroup(user.avatar);
    scene.remove(user.avatar);

    clearGroup(user.right.mesh);
    scene.remove(user.right.mesh);

    clearGroup(user.left.mesh);
    scene.remove(user.left.mesh);
  });

  elementMeshes = [];
  userMeshes = [];
}

function collideObject(indexTip) {
  const tmpVector = new THREE.Vector3();
  const indexPos = indexTip.getWorldPosition(tmpVector);

  const state = store.getState();
  const elements = selectAvailableElements(state);

  let collideEl = null;

  for (let i = 0; i < elements.length; i++) {
    const myElement = getElementById(elements[i].id);
    const box = new THREE.Box3().setFromObject(myElement.mesh);
    if (box.containsPoint(indexPos)) {
      collideEl = myElement;
      break;
    }
  }

  return collideEl;
}

function collideMultipleObjects(indexTip) {
  const tmpVector = new THREE.Vector3();
  const indexPos = indexTip.getWorldPosition(tmpVector);

  const state = store.getState();
  const elements = selectAvailableElements(state);
  const canGrabMultipleObjs = selectMultipleGrabbingPermission(state);

  let collideElements = [];

  for (let i = 0; i < elements.length; i++) {
    const myElement = getElementById(elements[i].id);
    const box = new THREE.Box3().setFromObject(myElement.mesh);
    if (box.containsPoint(indexPos)) {
      collideElements.push(myElement);
      if (!canGrabMultipleObjs) {
        break;
      }
    }
  }

  return collideElements;
}

function handleControllerSetup(inputSources) {
  const state = store.getState();
  const isInVR = selectisInVR(state);
  const isInTrial = selectisInTrialMode(state);

  let type;

  inputSources.forEach((info) => {
    if (info.handedness === "right") {
      isRightControllerConnected = true;
      isRHand = info.controllerType === "hand";
    } else {
      isLeftControllerConnected = true;
      isLHand = info.controllerType === "hand";
    }
    console.log(`Connected ${info.handedness} ${info.controllerType}`);
    type = info.controllerType;
  });

  if (isInTrial) {
    return;
  }

  // Remove handedness!
  const data = {
    type,
  };

  store.dispatch(sceneSlice.actions.emitControllerConnect(data));
}

export async function createUser(user) {
  const avatar = createAvatar(user.color);
  // avatar.position.set(user.x, 1.6, user.z);
  scene.add(avatar);
  if (user.type === "hand") {
    const { hand: leftHand, bones: leftBones } = await createHand("left", user.color);
    scene.add(leftHand);
    const { hand: rightHand, bones: rightBones } = await createHand("right", user.color);
    scene.add(rightHand);

    const userProfile = {
      id: user.id,
      type: user.type,
      avatar,
      left: {
        mesh: leftHand,
        bones: leftBones,
      },
      right: {
        mesh: rightHand,
        bones: rightBones,
      },
    };
    userMeshes.push({ ...userProfile });
  } else {
    const { controller: leftController } = await createController("left", user.color);
    scene.add(leftController);
    const { controller: rightController } = await createController("right", user.color);
    scene.add(rightController);

    const userProfile = {
      id: user.id,
      type: user.type,
      avatar,
      left: {
        mesh: leftController,
      },
      right: {
        mesh: rightController,
      },
    };
    userMeshes.push({ ...userProfile });
  }
}

export function updateUserVisibility(id, visibility) {
  const userIndex = userMeshes.findIndex((user) => user.id === id);

  if (userIndex >= 0) {
    userMeshes[userIndex].right.mesh.visible = visibility;
    userMeshes[userIndex].left.mesh.visible = visibility;
    userMeshes[userIndex].avatar.visible = visibility;
  }
}

export function updateElementScale(data) {
  const element = elementMeshes.find((element) => element.id === data.element.id);
  element.mesh.scale.set(data.element.scale, data.element.scale, data.element.scale);
}

export function updateElementTransform(data) {
  const element = elementMeshes.find((element) => element.id === data.elId);

  const position = new THREE.Vector3(data.position.x, data.position.y, data.position.z);

  const quaternion = new THREE.Quaternion(data.quaternion.x, data.quaternion.y, data.quaternion.z, data.quaternion.w);

  element.mesh.position.copy(position);
  element.mesh.setRotationFromQuaternion(quaternion);

  element.mesh.scale.set(data.scale, data.scale, data.scale);
}

export function deleteElement(data) {
  const elIndex = elementMeshes.findIndex((element) => element.id === data.id);

  scene.remove(elementMeshes[elIndex].mesh);
  clearGroup(elementMeshes[elIndex].mesh);

  elementMeshes.splice(elIndex, 1);
}

export function recenterElement(data) {
  const element = elementMeshes.find((element) => element.id === data.id);
  element.owner = null;
  element.isGrabbed = false;
  element.mesh.position.set(0, 1.5, 0);
}

function postElement(id) {
  const grabbedEl = elementMeshes.find((element) => element.id === id);
  const quaternion = new THREE.Quaternion();
  const position = new THREE.Vector3();
  grabbedEl.mesh.getWorldQuaternion(quaternion);
  grabbedEl.mesh.getWorldPosition(position);

  const data = {
    id,
    quaternion: {
      x: quaternion._x,
      y: quaternion._y,
      z: quaternion._z,
      w: quaternion._w,
    },
    position: {
      x: position.x,
      y: position.y,
      z: position.z,
    },
    scale: grabbedEl.mesh.scale.x,
  };

  store.dispatch(sceneSlice.actions.emitElementUpdate(data));
}

function postControllers() {
  const xrCamera = renderer.xr.getCamera();
  const camQuaternion = new THREE.Quaternion();
  const camPosition = new THREE.Vector3();
  camQuaternion.copy(xrCamera.quaternion);
  camPosition.copy(xrCamera.position);

  if (isRHand && isLHand) {
    let hand1Data = {};
    let hand2Data = {};

    if (!isColorUpdated) {
      const state = store.getState();
      const color = selectColor(state);
      const mesh1 = hand1.getObjectByProperty("type", "SkinnedMesh");
      const mesh2 = hand2.getObjectByProperty("type", "SkinnedMesh");

      const newMaterial = new THREE.MeshLambertMaterial({
        color,
      });

      if (mesh1 && mesh2) {
        mesh1.traverse(function (node) {
          if (node.isMesh) {
            node.material = newMaterial;
            node.material.needsUpdate = true;
          }
        });

        mesh2.traverse(function (node) {
          if (node.isMesh) {
            node.material = newMaterial;
            node.material.needsUpdate = true;
          }
        });
        isColorUpdated = true;
      }
    }

    for (let i = 0; i < joints.length; i++) {
      const joint = joints[i];
      hand1Data.handedness = hand1.children[0].xrInputSource.handedness;
      hand2Data.handedness = hand2.children[0].xrInputSource.handedness;
      if (hand1.joints[joint]) {
        const quaternion1 = new THREE.Quaternion();
        const position1 = new THREE.Vector3();
        hand1.joints[joint].getWorldQuaternion(quaternion1);
        hand1.joints[joint].getWorldPosition(position1);
        hand1Data[joint] = {
          position: { x: position1.x, y: position1.y, z: position1.z },
          quaternion: {
            x: quaternion1._x,
            y: quaternion1._y,
            z: quaternion1._z,
            w: quaternion1._w,
          },
          visible: hand1.joints[joint].visible,
        };
      }

      if (hand2.joints[joint]) {
        const quaternion2 = new THREE.Quaternion();
        const position2 = new THREE.Vector3();
        hand2.joints[joint].getWorldQuaternion(quaternion2);
        hand2.joints[joint].getWorldPosition(position2);
        hand2Data[joint] = {
          position: { x: position2.x, y: position2.y, z: position2.z },
          quaternion: {
            x: quaternion2._x,
            y: quaternion2._y,
            z: quaternion2._z,
            w: quaternion2._w,
          },
          visible: hand2.joints[joint].visible,
        };
      }
    }

    const data = {
      hand1: hand1Data,
      hand2: hand2Data,
      avatar: {
        position: { x: camPosition.x, y: camPosition.y, z: camPosition.z },
        quaternion: {
          x: camQuaternion._x,
          y: camQuaternion._y,
          z: camQuaternion._z,
          w: camQuaternion._w,
        },
      },
    };

    store.dispatch(sceneSlice.actions.emitControllerUpdate(data));
  } else {
    const quaternion1 = new THREE.Quaternion();
    const position1 = new THREE.Vector3();
    controllerGrip1.getWorldQuaternion(quaternion1);
    controllerGrip1.getWorldPosition(position1);

    const quaternion2 = new THREE.Quaternion();
    const position2 = new THREE.Vector3();
    controllerGrip2.getWorldQuaternion(quaternion2);
    controllerGrip2.getWorldPosition(position2);

    const data = {
      controller1: {
        position: { x: position1.x, y: position1.y, z: position1.z },
        quaternion: {
          x: quaternion1._x,
          y: quaternion1._y,
          z: quaternion1._z,
          w: quaternion1._w,
        },
      },
      controller2: {
        position: { x: position2.x, y: position2.y, z: position2.z },
        quaternion: {
          x: quaternion2._x,
          y: quaternion2._y,
          z: quaternion2._z,
          w: quaternion2._w,
        },
      },
      avatar: {
        position: { x: camPosition.x, y: camPosition.y, z: camPosition.z },
        quaternion: {
          x: camQuaternion._x,
          y: camQuaternion._y,
          z: camQuaternion._z,
          w: camQuaternion._w,
        },
      },
    };

    store.dispatch(sceneSlice.actions.emitControllerUpdate(data));
  }
}

export function renderUserUpdate(data) {
  const user = userMeshes.find((user) => user.id === data.id);
  const controllerData = data.controllerData;
  const isUserCreated = user && user.left && user.right;

  if (isUserCreated) {
    const avatarPos = new THREE.Vector3(
      controllerData.avatar.position.x,
      controllerData.avatar.position.y,
      controllerData.avatar.position.z
    );

    const avatarQuat = new THREE.Quaternion(
      controllerData.avatar.quaternion.x,
      controllerData.avatar.quaternion.y,
      controllerData.avatar.quaternion.z,
      controllerData.avatar.quaternion.w
    );
    user.avatar.position.copy(avatarPos);
    user.avatar.quaternion.copy(avatarQuat);
  }

  // Received hand data - user was created with hands
  if (controllerData.hand1 && controllerData.hand2 && isUserCreated && user.type === "hand") {
    const leftHand = controllerData.hand1.handedness === "left" ? controllerData.hand1 : controllerData.hand2;
    const rightHand = controllerData.hand2.handedness === "right" ? controllerData.hand2 : controllerData.hand1;
    for (let i = 0; i < user.left.bones.length; i++) {
      const bone = user.left.bones[i];
      const joint = bone.jointName;

      if (bone && leftHand[joint]) {
        const position1 = new THREE.Vector3(
          leftHand[joint].position.x,
          leftHand[joint].position.y,
          leftHand[joint].position.z
        );

        const quaternion1 = new THREE.Quaternion(
          leftHand[joint].quaternion.x,
          leftHand[joint].quaternion.y,
          leftHand[joint].quaternion.z,
          leftHand[joint].quaternion.w
        );

        bone.position.copy(position1);
        bone.setRotationFromQuaternion(quaternion1);
      }
    }

    for (let i = 0; i < user.right.bones.length; i++) {
      const bone = user.right.bones[i];
      const joint = bone.jointName;

      if (bone && rightHand[joint]) {
        const position2 = new THREE.Vector3(
          rightHand[joint].position.x,
          rightHand[joint].position.y,
          rightHand[joint].position.z
        );

        const quaternion2 = new THREE.Quaternion(
          rightHand[joint].quaternion.x,
          rightHand[joint].quaternion.y,
          rightHand[joint].quaternion.z,
          rightHand[joint].quaternion.w
        );

        bone.position.copy(position2);
        bone.setRotationFromQuaternion(quaternion2);
      }
    }
  } else if (controllerData.controller1 && controllerData.controller2 && isUserCreated && user.type === "controller") {
    // Received controller data - user was created with controllers
    const position1 = new THREE.Vector3(
      controllerData.controller1.position.x,
      controllerData.controller1.position.y,
      controllerData.controller1.position.z
    );

    const quaternion1 = new THREE.Quaternion(
      controllerData.controller1.quaternion.x,
      controllerData.controller1.quaternion.y,
      controllerData.controller1.quaternion.z,
      controllerData.controller1.quaternion.w
    );
    user.left.mesh.position.copy(position1);
    user.left.mesh.setRotationFromQuaternion(quaternion1);

    const position2 = new THREE.Vector3(
      controllerData.controller2.position.x,
      controllerData.controller2.position.y,
      controllerData.controller2.position.z
    );

    const quaternion2 = new THREE.Quaternion(
      controllerData.controller2.quaternion.x,
      controllerData.controller2.quaternion.y,
      controllerData.controller2.quaternion.z,
      controllerData.controller2.quaternion.w
    );
    user.right.mesh.position.copy(position2);
    user.right.mesh.setRotationFromQuaternion(quaternion2);
  } 
}

export function removeUserFromScene(id) {
  const userIndex = userMeshes.findIndex((user) => user.id === id);

  if (userIndex >= 0) {
    scene.remove(userMeshes[userIndex].avatar);
    clearGroup(userMeshes[userIndex].avatar);

    scene.remove(userMeshes[userIndex].right.mesh);
    clearGroup(userMeshes[userIndex].right.mesh);

    scene.remove(userMeshes[userIndex].left.mesh);
    clearGroup(userMeshes[userIndex].left.mesh);

    userMeshes.splice(userIndex, 1);
  }
}

export async function addElement(data) {
  const transparent = data.element.name === "map";
  const element = await createElement(data.element.url, transparent);
  element.scale.set(data.element.scale, data.element.scale, data.element.scale);
  element.position.set(data.element.position.x, data.element.position.y, data.element.position.z);
  const quaternion = new THREE.Quaternion(
    data.element.quaternion.x,
    data.element.quaternion.y,
    data.element.quaternion.z,
    data.element.quaternion.w
  );
  element.setRotationFromQuaternion(quaternion);

  scene.add(element);
  const elementProfile = {
    mesh: element,
    ...data.element,
  };
  elementMeshes.push(elementProfile);
}

function getElementById(id) {
  const element = elementMeshes.find((el) => el.id === id);
  return element;
}

export function updateSlide(data) {
  const texture = textureLoader.load(data.url);
  texture.colorSpace = THREE.SRGBColorSpace;
  slideMat.map = texture;
  slideMat.needsUpdate = true;
}

export function startVRSession() {
  async function onSessionStarted(session) {
    await renderer.xr.setSession(session);
  }
  const sessionInit = {
    optionalFeatures: ["local-floor", "bounded-floor", "hand-tracking", "layers"],
  };
  navigator.xr.requestSession("immersive-vr", sessionInit).then(onSessionStarted);
}

export function startARSession() {
  async function onSessionStarted(session) {
    environment.visible = false;
    await renderer.xr.setSession(session);
  }
  const sessionInit = {
    optionalFeatures: ["local-floor", "bounded-floor", "hand-tracking", "layers", "unbounded"],
  };
  navigator.xr.requestSession("immersive-ar", sessionInit).then(onSessionStarted);
}

export function endVRSession() {
  const state = store.getState();
  const isInVR = selectisInVR(state);
  if (isInVR) {
    renderer.xr.getSession().end();
  }
}

function onKeyDown(event) {
  speed = 50;
  switch (event.code) {
    case "ArrowUp":
    case "KeyW":
      moveForward = true;
      break;

    case "ArrowLeft":
    case "KeyA":
      moveLeft = true;
      break;

    case "ArrowDown":
    case "KeyS":
      moveBackward = true;
      break;

    case "ArrowRight":
    case "KeyD":
      moveRight = true;
      break;
  }
}

function onKeyUp(event) {
  switch (event.code) {
    case "ArrowUp":
    case "KeyW":
      moveForward = false;
      break;

    case "ArrowLeft":
    case "KeyA":
      moveLeft = false;
      break;

    case "ArrowDown":
    case "KeyS":
      moveBackward = false;
      break;

    case "ArrowRight":
    case "KeyD":
      moveRight = false;
      break;
  }
}

function handleExport() {
  scene.add(dummyObj);
  // dummyObj.rotateY(Math.PI);

  let string = "";
  elementMeshes.forEach((element) => {
    const tmpVector = new THREE.Vector3();
    const position = element.mesh.getWorldPosition(tmpVector);
    const tmpQuaternion = new THREE.Quaternion();
    const quaternion = element.mesh.getWorldQuaternion(tmpQuaternion);
    string += `${element.name}\n
        {x:${position.x.toFixed(2)}, y:${position.y.toFixed(2)}, z:${position.z.toFixed(2)}}\n
        {x:${quaternion._x.toFixed(2)}, y:${quaternion._y.toFixed(2)}, z:${quaternion._z.toFixed(
          2
        )}, w:${quaternion._w.toFixed(2)}}\n\n`;
  });
  console.log(string);
}

function onMouseDown(event) {
  // Handle only primary button.
  if (event.button !== 0) {
    return;
  }

  const state = store.getState();
  const isShowingInfoModal = selectIsShowingInfoModal(state);
  const isShowingOverlay = selectIsShowingHelpOverlay(state);

  if (isShowingInfoModal && event.target.id === "app") {
    store.dispatch(sessionSlice.actions.toggleInfoModal());
  }

  if (isShowingInfoModal || isShowingOverlay) {
    return;
  }

  isMouseDown = true;
  previousMouseEvent.screenX = event.screenX;
  previousMouseEvent.screenY = event.screenY;
  showGrabbingCursor();
}

function onMouseMove(event) {
  let movementX;
  let movementY;

  // Not dragging
  if (!isMouseDown) {
    return;
  }

  movementX = event.screenX - previousMouseEvent.screenX;
  movementY = event.screenY - previousMouseEvent.screenY;

  previousMouseEvent.screenX = event.screenX;
  previousMouseEvent.screenY = event.screenY;

  euler.setFromQuaternion(camera.quaternion);

  euler.y -= movementX * 0.002;
  euler.x -= movementY * 0.002;

  euler.x = Math.max(_PI_2 - maxPolarAngle, Math.min(_PI_2 - minPolarAngle, euler.x));

  camera.quaternion.setFromEuler(euler);
}

function onMouseUp(event) {
  isMouseDown = false;
  hideGrabbingCursor();
}

function onTouchStart(event) {
  if (event.touches.length !== 1) {
    return;
  }

  const state = store.getState();
  const isShowingInfoModal = selectIsShowingInfoModal(state);
  const isShowingOverlay = selectIsShowingHelpOverlay(state);

  if (isShowingInfoModal && event.target.id === "app") {
    store.dispatch(sessionSlice.actions.toggleInfoModal());
  }

  if (isShowingInfoModal || isShowingOverlay) {
    return;
  }

  touchStart = {
    x: event.touches[0].pageX,
    y: event.touches[0].pageY,
  };

  touchStarted = true;
}

function onTouchEnd(event) {
  touchStarted = false;
}

function onTouchMove(event) {
  let movementX;
  let movementY;

  // Not dragging
  if (!touchStarted) {
    return;
  }

  movementX = event.touches[0].pageX - touchStart.x;
  movementY = event.touches[0].pageY - touchStart.y;

  touchStart.x = event.touches[0].pageX;
  touchStart.y = event.touches[0].pageY;

  euler.setFromQuaternion(camera.quaternion);

  euler.y -= movementX * 0.002;
  euler.x -= movementY * 0.002;

  euler.x = Math.max(_PI_2 - maxPolarAngle, Math.min(_PI_2 - minPolarAngle, euler.x));

  camera.quaternion.setFromEuler(euler);
}

function showGrabbingCursor() {
  const app = document.getElementById("app");
  app.style.cursor = "grabbing";
}

function hideGrabbingCursor() {
  const app = document.getElementById("app");
  app.style.cursor = "";
}

export function enableWasd() {
  controls.enabled = false;
  isWasdEnabled = true;
  camera.position.set(0, 1.6, 0);
  camera.lookAt(0, 1.6, -1);
  window.addEventListener("mousedown", onMouseDown, false);
  window.addEventListener("mousemove", onMouseMove, false);
  window.addEventListener("mouseup", onMouseUp, false);
  window.addEventListener("touchstart", onTouchStart, false);
  window.addEventListener("touchmove", onTouchMove, false);
  window.addEventListener("touchend", onTouchEnd, false);
}

export function disableWasd() {
  controls.enabled = true;
  isWasdEnabled = false;
  camera.position.set(0, 1.6, 3);
  window.removeEventListener("mousedown", onMouseDown);
  window.removeEventListener("mousemove", onMouseMove);
  window.removeEventListener("mouseup", onMouseUp);
  window.removeEventListener("touchstart", onTouchStart);
  window.removeEventListener("touchmove", onTouchMove);
  window.removeEventListener("touchend", onTouchEnd);
}

export function updatePositionFromJoystick(vector) {
  speed = 35 * Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  if (vector.x > 0) {
    moveRight = true;
    moveLeft = false;
  } else {
    moveLeft = true;
    moveRight = false;
  }

  if (vector.y > 0) {
    moveForward = true;
    moveBackward = false;
  } else {
    moveBackward = true;
    moveForward = false;
  }
}

export function joystickUpdateEnd() {
  moveRight = false;
  moveLeft = false;
  moveBackward = false;
  moveForward = false;
}

function formatInputSources(session) {
  if (!session || !session.inputSources) {
    return [];
  }

  return Array.from(session.inputSources).map((inputSource) => {
    let controllerType = "unknown";

    if (inputSource.hand) {
      controllerType = "hand";
    } else if (inputSource.targetRayMode === "tracked-pointer") {
      controllerType = "controller";
    }

    return {
      controllerType,
      handedness: inputSource.handedness,
    };
  });
}

const formatTrackedSources = (trackedSources) => {
  if (!trackedSources || trackedSources.length === 0) {
    return "No tracked sources available";
  }
  return Array.from(trackedSources)
    .map((source) => source.profiles.join(", "))
    .join(" | ");
};
